<router-outlet></router-outlet>
<ng-container *ngIf="loadingService.isLoading$ | async as loadingMap">
  <div class="app-loading">
    <div class="loader-wrapper">
      <div class="loader">
        <div class="loader loader-inner"></div>
      </div>
    </div>
  </div>
</ng-container>
