<div class="left-menu">
    <div class="logo-container">
        <div class="logo"></div>
    </div>
    <ul #menuListRef>
        <li *ngFor="let item of menu" [ngClass]="{'extended': item.extended}" routerLinkActive="active">
            <a [routerLink]="!item.children || item.children.length == 0 ? item.route : null"
                [matTooltip]="item.title | translate"
                matTooltipPosition="right"
                (click)="onToggleExtendMenuItem(item)">
                <mat-icon >{{item.icon}}</mat-icon>
            </a>
        </li>
    </ul>
</div>
