<div class="app" [ngClass]="{'extended-menu': menuExtended}" (window:resize)="onResize()">
  <app-left-menu (extendeChanged)="onExtendedChange($event)"></app-left-menu>
  <div class="main-container" [ngClass]="sidenavExpand ? 'menu-expand' : 'menu-shrink'">
    <div class="sidenav-action-wrapper justify-content-end" [ngClass]="sidenavExpand ? 'shrink' : 'expand'">
      <button color="base" class="sidenav-action-button" (click)="sidebarChange()" mat-mini-fab><mat-icon svgIcon="navi"></mat-icon></button>
    </div>
    <div class="top-menu">
      <div class="top-spacer"></div>
      <h3 class="title">
        {{"Welcome" | translate}}
      </h3>
      <span class="flex-spacer"></span>
      <!-- <button class="menu-trigger notification-btn" mat-button [matMenuTriggerFor]="menu" (menuOpened)="getNotification()">

                <mat-icon [matBadge]="total || null" matBadgeColor="warn" aria-hidden="false">notifications</mat-icon>
            </button>
            <mat-menu #menu class="notification-panel" xPosition="before" yPosition="below">
                <div class="notification-container">
                    <h3 class="title d-flex">
                        {{'notification' | translate}}
                        <button class="ml-auto btn-read" mat-button color="primary" aria-label="Mark all as read"
                            (click)="markNotificationAllAsRead()">
                            {{'mark_all_as_read' | translate}}
                        </button>
                    </h3>
                    <div class="notification-content">
                        <ul>
                            <li *ngFor="let item of notifications" (click)="markNotificationAsRead(item._id!)">
                                <ng-container *ngIf="item | notificationRouter as router; else notificationItem">
                                    <a [routerLink]="router">
                                        <ng-template [ngTemplateOutlet]="notificationItem">
                                        </ng-template>
                                    </a>
                                </ng-container>
                                <ng-template #notificationItem>
                                    <div class="notification-item">
                                        <div class="avatar-preview">
                                            <div class="imagePreview" *ngIf="this.senderProfiles[item.sender._id!]"
                                                [ngStyle]="{'background-image':'url(' + this.senderProfiles[item.sender._id!] +')'}">
                                            </div>
                                        </div>
                                        <div class="content">
                                            <section class="message">
                                                <b>{{ item.sender | empFullName: translateService.currentLang }}</b>
                                                {{item.msg_code |
                                                messageCodeKey
                                                |
                                                translate: {type: (item.type| lowercase | translate) | lowercase} }}

                                            </section>
                                            <p class="time">{{item.createdAt | date:'dd/MM/yyyy, h:mm:ss a'}}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                </div>

            </mat-menu> -->
      <button class="menu-trigger" mat-button [matMenuTriggerFor]="matMenu">
        <span class="profile" style="background-image: url('{{profilePicture}}')"></span>
        <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #matMenu="matMenu" yPosition="below">
        <div class="emp">
          <div class="profile" style="background-image: url('{{profilePicture}}')"></div>
          <!-- <div *ngIf="roles?.length" class="name-role">
                        <p class="name">{{ $any(user) | empFullName: translateService.currentLang }}</p>
                        <p *ngIf="roles" class="role">{{ roles[0].name }}</p>
                    </div> -->
          <div class="name-role">
            <p class="name">{{ "Test User" }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item>
          <mat-icon>password </mat-icon> {{'change_password' | translate}}
        </button>
        <!-- <a *ngIf="authService.employeeId" mat-menu-item routerLink="/profile/detail/identity/info">
                    <mat-icon>account_circle</mat-icon> {{'my_profile' |
                    translate}}
                </a> -->
        <button mat-menu-item [matMenuTriggerFor]="languageMenu">
          <mat-icon>language</mat-icon> {{'language' | translate}}
        </button>
        <a mat-menu-item *ngIf="authService.isAuth" routerLink="/login" (click)="authService.logout()">
          <mat-icon><span class="material-symbols-outlined">logout</span></mat-icon> {{'logout' | translate}}
        </a>
      </mat-menu>
      <mat-menu #languageMenu="matMenu" yPosition="below">
        <button mat-menu-item *ngFor="let lang of translateService.getLangs()" (click)="useLanguage(lang)">
          <mat-icon>{{translateService.currentLang ==
            lang?'radio_button_checked':'radio_button_unchecked'}}</mat-icon>{{lang | translate}}
        </button>
      </mat-menu>
    </div>
    <div class="app-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
