import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service'

export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isAuth) {
    router.navigate(['/auth/login']);
    return false;
  } else if (!route.data['permissions'] || (route.data['permissions'] as string[])?.find(p => authService.permissions.includes(p))) {
    return true;
  } else {
    router.navigate(['/']);
    return false;
  }
}