import { DatePipe } from "@angular/common";
import { NativeDateAdapter } from "@angular/material/core";
import { DateTypeEnum } from "../models/enums/date-type.enum";

export const DATE_INPUT_FORMAT_FORM = {
    [DateTypeEnum.Year]: "yyyy",
    [DateTypeEnum.MonthYear]: "MM/yyyy",
    [DateTypeEnum.DayMonthYear]: "dd/MM/yyyy"
}

const DATE_INPUT_FORMAT_REGEG = {
    [DateTypeEnum.Year]: /^\d{4}$/,
    [DateTypeEnum.MonthYear]: /^(0[1-9]|1[0-2])\/(\d{4})$/, //  /^(0[1-9]|1[0-2])-(\d{4})$/
    [DateTypeEnum.DayMonthYear]: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/ //   /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/
}
export interface DateParse {
    dateInput: string;
}

export type DateDisplay = DateParse & {
    monthYearLabel?: string;
    dateA11yLabel?: string;
    monthYearA11yLabel?: string;
};
export class CustomDateFormat {
    constructor(){}
    private _parse: DateParse = {
        dateInput: DateTypeEnum.DayMonthYear
    };
    private _display: DateDisplay = {
        dateInput: DateTypeEnum.DayMonthYear,
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: DateTypeEnum.DayMonthYear,
        monthYearA11yLabel: 'yyyy'
    };

    set parse(parse: DateParse) {
        this._parse = Object.assign({}, this._parse, parse);
    }

    get parse(): DateParse {
        return this._parse;
    }

    set display(display: DateDisplay) {
        this._display = Object.assign({}, this._display, display);
    }

    get display(): DateDisplay {
        return this._display;
    }

    updateDateFormat(parse: DateParse, display?: DateDisplay) {
        this.parse = parse;
        if (!display) {
            display = parse;
        }
        this.display = display;
    }
}

export class CustomDateAdapter extends NativeDateAdapter {
    constructor(matDateLocale: string) {
        super(matDateLocale)
    }
    override parse(value: string, parseFormat?: any): Date | null {
        try {
            if(!value){
                return null;
            }
            if (!parseFormat) {
                return new Date(value)
            } else {
                switch (parseFormat) {
                    case DateTypeEnum.DayMonthYear:
                        if (value.match(DATE_INPUT_FORMAT_REGEG[DateTypeEnum.DayMonthYear])) {
                            return new Date(parseInt(value.substring(6)), parseInt(value.substring(3, 5)) - 1, parseInt(value.substring(0, 2)))
                        }
                        break;
                    case DateTypeEnum.MonthYear:
                        if (value.match(DATE_INPUT_FORMAT_REGEG[DateTypeEnum.MonthYear])) {
                            return new Date(parseInt(value.substring(3)), parseInt(value.substring(0, 2)) - 1, 1)
                        }
                        break;
                    case DateTypeEnum.Year:
                        if (value.match(DATE_INPUT_FORMAT_REGEG[DateTypeEnum.Year])) {
                            return new Date(parseInt(value), 0, 1)
                        }
                        break;
                }
            }
        } catch (error) {
            
        }

        return  this.invalid();;
    }
    override format(date: Date, displayFormat: any): string {
        let datePipe = new DatePipe('en-GB');
        if (Object.values(DateTypeEnum).includes(displayFormat)) {
            switch (displayFormat) {
                case DateTypeEnum.DayMonthYear:
                    return datePipe.transform(date, DATE_INPUT_FORMAT_FORM[DateTypeEnum.DayMonthYear]) || date.toLocaleDateString();
                case DateTypeEnum.MonthYear:
                    return datePipe.transform(date, DATE_INPUT_FORMAT_FORM[DateTypeEnum.MonthYear]) || date.toLocaleDateString();
                case DateTypeEnum.Year:
                    return datePipe.transform(date, DATE_INPUT_FORMAT_FORM[DateTypeEnum.Year]) || date.toLocaleDateString();
                default:
                    return date.toLocaleDateString()
            }
        } else {
            return datePipe.transform(date, displayFormat) || date.toLocaleDateString();
        }

    }
}
