import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { RolePermissionEnum } from 'src/app/models/enums/role-permission.enum';
import { MenuItem } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export const MENU: MenuItem[] = [
  {
    icon: 'home',
    title: 'home',
    permissions: [RolePermissionEnum.p_user, RolePermissionEnum.p_admin],
    route: '/home',
  },
  {
    icon: 'dns',
    title: 'administration',
    permissions: [RolePermissionEnum.p_admin],
    route: '/administration'
  },
  {
    icon: 'build',
    title: 'product_config',
    permissions: [RolePermissionEnum.p_admin],
    route: '/product-config'
  },
  {
    icon: 'eco',
    title: 'underwriting',
    permissions: [RolePermissionEnum.p_user, RolePermissionEnum.p_admin],
    route: '/underwriting'
  },
  {
    icon: 'trending_up',
    title: 'sale_marketing',
    permissions: [RolePermissionEnum.p_admin],
    route: '/sale-marketing'
  },
  {
    icon: 'percent',
    title: 'commission',
    permissions: [RolePermissionEnum.p_admin],
    route: '/commission'
  },
  {
    icon: 'verified_user',
    title: 'reinsurance',
    permissions: [RolePermissionEnum.p_admin],
    route: '/reinsurance'
  },
  {
    icon: 'explore',
    title: 'geographic_area',
    permissions: [RolePermissionEnum.p_admin],
    route: '/geographic-area'
  },
  {
    icon: 'paid',
    title: 'claim',
    permissions: [RolePermissionEnum.p_admin],
    route: '/claim'
  },
  {
    icon: 'receipt',
    title: 'reciepting',
    permissions: [RolePermissionEnum.p_user, RolePermissionEnum.p_admin],
    route: '/receipting'
  },
  {
    icon: 'wallet',
    title: 'Payment',
    permissions: [RolePermissionEnum.p_admin],
    route: '/payment'
  },
  {
    icon: 'history_edu',
    title: 'general_ledger',
    permissions: [RolePermissionEnum.p_admin],
    route: '/general-ledger'
  }
];


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.scss'
})
export class LeftMenuComponent implements OnInit, OnDestroy {

  @Output() extendeChanged = new EventEmitter<boolean>();
  @ViewChild('menuListRef') menuListRef!: ElementRef;
  menu!: MenuItem[];
  menuExtended = true;
  isMenuOverlap = false;
  disposerFn?: () => void;
  quota!: string;
  bigScreenExtend = false; // store status extend for big screen when screen change to small

  constructor(
    public authService: AuthService,
    public languageService: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
  ) {
    this.menu = JSON.parse(JSON.stringify(MENU));
    // this.menu = this.menu.filter(m => !m.permissions || m.permissions.find(p => authService.permissions.includes(p)));
    // this.menu = this.menu.filter(m => !m.children || m.children.length > 0);

    if (this.menu.length > 0) {
      if (this.router.url == '/') {
        if (this.menu[0].children?.length) {
          this.router.navigate([this.menu[0].children![0].route])
        } else {
          this.router.navigate([this.menu[0].route])
        }
      }
    } else {
      this.router.navigate(['/'])
    }

    const breakPoinMediaQuery = '(max-width: 1400px)';
    const mediaQueryList = window.matchMedia(breakPoinMediaQuery);

    this.isMenuOverlap = mediaQueryList.matches;
    const extended = this.localStorageService.get(LocalStorageEnum.menuExtended);
    this.menuExtended = !extended || extended == 'true';
    this.bigScreenExtend = this.menuExtended
    if (this.isMenuOverlap && this.menuExtended) {
      this.onToggleExtendMenu();
    }
    this.refreshListenWindowClick();

    mediaQueryList.addEventListener('change', event => {
      this.isMenuOverlap = event.matches;
      if (this.isMenuOverlap) {
        this.bigScreenExtend = this.menuExtended;
        if (this.menuExtended) {
          this.onToggleExtendMenu();
        }
      } else {
        if ((this.bigScreenExtend && !this.menuExtended) || (!this.bigScreenExtend && this.menuExtended)) {
          this.onToggleExtendMenu();
        }
      }
      this.refreshListenWindowClick();
      if (!this.isMenuOverlap && !this.menuExtended) {
        this.menu.forEach(m => m.extended = false);
      }
    })
  }

  onToggleExtendMenu() {
    this.menuExtended = !this.menuExtended;
    this.extendeChanged.emit(this.menuExtended);
    if (!this.isMenuOverlap) {
      this.localStorageService.set(LocalStorageEnum.menuExtended, this.menuExtended.toString());
    }
    this.refreshListenWindowClick();
  }

  refreshListenWindowClick() {
    if ((!this.menuExtended && !this.isMenuOverlap) || (this.isMenuOverlap && this.menuExtended)) {
      this.listenMouse();
    } else if (this.disposerFn) {
      this.disposerFn();
      this.disposerFn = undefined
    }
  }

  private listenMouse() {
    if (this.disposerFn) {
      return;
    }
    this.disposerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.menuExtended) {
        if (!(this.menuListRef?.nativeElement as HTMLDivElement).contains(e.target as HTMLElement)) {
          this.menu.forEach(m => m.extended = false);
        }
      } else if (this.isMenuOverlap) {
        if ((this.menuListRef?.nativeElement as HTMLDivElement).contains(e.target as HTMLElement)) {
          const target = e.target as HTMLElement;
          const a = target.tagName === 'A' ? target : target.closest('a');
          if (!a || (a as HTMLAnchorElement).href) {
            this.onToggleExtendMenu();
          }
        } else if (!(this.menuListRef?.nativeElement as HTMLDivElement).parentElement!.contains(e.target as HTMLElement)) {
          this.onToggleExtendMenu();
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.disposerFn) {
      this.disposerFn();
      this.disposerFn = undefined;
    }
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
  }

  ngOnInit(): void {
    if (this.menuExtended || this.isMenuOverlap) {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.router.url.startsWith(this.menu[i].route)) {
          this.menu[i].extended = true;
        }
      }
    }
  }

  onToggleExtendMenuItem(item: MenuItem) {
    const currentStatus = item.extended;
    this.menu.forEach(m => m.extended = false);
    item.extended = !currentStatus;

  }
}
