import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContainerComponent } from './components/container/container.component';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'administration',
        loadChildren: () => import('./routes/administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'sale-marketing',
        loadChildren: () => import('./routes/sale-marketing/sale-marketing.module').then(m => m.SaleMarketingModule)
      },
      {
        path: 'commission',
        loadChildren: () => import('./routes/commission/commission.module').then(m => m.CommissionModule)
      },
      {
        path: 'underwriting',
        loadChildren: () => import('./routes/underwriting/underwriting.module').then(m => m.UnderwritingModule)
      },
      {
        path: 'reinsurance',
        loadChildren: () => import('./routes/reinsurance/reinsurance.module').then(m => m.ReinsuranceModule)
      },
      {
        path: 'geographic-area',
        loadChildren: () => import('./routes/geographic-area/geographic-area.module').then(m => m.GeographicAreaModule)
      },
      {
        path: 'claim',
        loadChildren: () => import('./routes/claim/claim.module').then(m => m.ClaimModule)
      },
      {
        path: 'product-config',
        loadChildren: () => import('./routes/product-config/product-config.module').then(m => m.ProductConfigModule)
      },
      {
        path: 'receipting',
        loadChildren: () => import('./routes/receipting/receipting.module').then(m => m.ReceiptingModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./routes/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'general-ledger',
        loadChildren: () => import('./routes/general-ledger/general-ledger.module').then(m => m.GeneralLedgerModule)
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./routes/authenticate/authenticate.module').then(m => m.AuthenticateModule)
  },
  {
    path: "**",
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
