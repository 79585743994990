import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from 'src/app/models/enums/languege.enum';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LoadingService } from 'src/app/services/loading.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CustomDateAdapter, CustomDateFormat } from 'src/app/utils/date-adapter.util';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    {
      provide: MAT_DATE_FORMATS, useClass: CustomDateFormat
    }
  ]
})
export class AppComponent {
  title = 'insura-genal-web-admin';
  private iconPath: string = "/assets/icons/";
	private iconExt: string = '.svg';

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
		private icon: MatIconRegistry,
		private sanitizer: DomSanitizer,
    public loadingService: LoadingService
    ){
    this.translateService.addLangs(Object.values(LanguageEnum));
    let lang = this.localStorageService.get(LocalStorageEnum.languege);
    if (!lang || !Object.values(LanguageEnum).includes(lang as unknown as LanguageEnum)) {
      lang = LanguageEnum.en;
    }


    this.addIcons();
    this.translateService.use(lang);
  }


  addIcons() {
		this.addIcon('car_green_icon');
  }

  private addIcon(name: string): void {
		this.icon.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(this.iconPath + name + this.iconExt));
	}
}
