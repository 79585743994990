import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { MyNotification } from 'src/app/models/notification';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
})
export class ContainerComponent implements OnInit {
  notifications?: MyNotification[] = [];

  profilePicture?: string = '/assets/imgs/user.svg';

  user?: User;

  total?: number = 0;

  menuExtended = false;
  sidenavExpand: boolean = true;
  mobileQuery!: boolean;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    private destroyRef: DestroyRef,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    //import svg icon set
    matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/action-icons.svg'
      )
    );
    matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/nav-icons.svg'
      )
    );

    const breakPoinMediaQuery = '(max-width: 1400px)';
    const mediaQueryList = window.matchMedia(breakPoinMediaQuery);

    const isMenuOverlap = mediaQueryList.matches;
    if (isMenuOverlap) {
      this.menuExtended = false;
    } else {
      const extended = this.localStorageService.get(
        LocalStorageEnum.menuExtended
      );
      this.menuExtended = !extended || extended == 'true';
    }
  }

  ngOnInit(): void {
    this.onResize();
    this.breakpointObserver
      .observe([Breakpoints.Large])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileQuery = false;
        }
      });
  }

  onResize(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
      this.sidenavExpand = false;
    }
    if (window.innerWidth > 959 && window.innerWidth <= 1280) {
      this.sidenavExpand = false;
      this.mobileQuery = false;
    } else if (window.innerWidth > 1280) {
      this.sidenavExpand = true;
      this.mobileQuery = false;
    }
  }

  markNotificationAsRead(id: string) {
    // this.notification.markAsRead(id).subscribe(res => {
    //   this.getNotification();
    // })
  }
  markNotificationAllAsRead() {
    // this.notification.markAllAsRead().subscribe(res => {
    //   this.getNotification();
    // })
  }

  getNotification() {
    // this.isLoadingNotification = true;
    // this.notification.getList().subscribe(res => {
    //   this.notifications = res.list;
    //   this.isLoadingNotification = false;
    //   this.total = res.total;
    //   this.notifications.forEach(item => {
    //     this.getUserProfileImage(item.sender._id!)
    //   })
    // })
  }

  onExtendedChange(value: boolean) {
    this.menuExtended = value;
  }

  sidebarChange(): void {
    this.sidenavExpand = !this.sidenavExpand;
  }

  useLanguage(language: string) {
    this.translateService.use(language);
    this.localStorageService.set(LocalStorageEnum.languege, language);
  }
}
