import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { APIResponseCodeEnum } from '../models/enums/api-response-code.enum';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
//NOTE: Refresh token fail while token is not expired it seem because in development angular refresh page at same time so when client from multi tab refresh token at same time using 1 token only first request success other will fail and logout.
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
  }
  isRefreshingToken: boolean = false;
  tokenBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const refresh_token = this.localStorageService.get(LocalStorageEnum.refresh_token);
    let token = this.localStorageService.get(LocalStorageEnum.token);
    const isRefreshTokenRequest = this.authService.isRefreshTokenUrl(req.url);
    if (isRefreshTokenRequest) {
      req = this.addToken(req, refresh_token)
    } else if (token) {
      req = this.addToken(req, token)
    }
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === APIResponseCodeEnum.expired_token) {
          if (isRefreshTokenRequest) {
            this.authService.logout()
          } else if (refresh_token) {
            if (!this.isRefreshingToken) {
              this.isRefreshingToken = true;
              // Reset here so that the following requests wait until the token
              // comes back from the refreshToken call.
              this.tokenBehaviorSubject.next('');
              // get a new token via userService.refreshToken
              return this.authService.refreshToken().
                pipe(
                  switchMap((res) => {
                    this.localStorageService.set(LocalStorageEnum.token, res.token);
                    this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
                    this.tokenBehaviorSubject.next(res.token);
                    return next.handle(this.addToken(req, res.token));
                  }),
                  catchError(err => {
                    // If we don't get a new token, we are in trouble so logout.
                    if(this.authService.isRefreshTokenUrl(err.url) || err?.message === 'unauthorized'){
                      this.authService.logout()
                    }

                    return throwError(() => err);
                  }),
                  finalize(() => {
                    this.isRefreshingToken = false;
                  })
                );
            } else {
              return this.tokenBehaviorSubject
                .pipe(
                  filter(token => token != ''),
                  take(1),
                  switchMap(token => {
                    return next.handle(this.addToken(req, token));
                  })
                );
            }
          } else {
            this.authService.logout()
          }
        } else if (err.status === APIResponseCodeEnum.invalid_token) {
          this.authService.logout()
        }
        return throwError(() => err);
      })
    )
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        "Authorization": 'Bearer ' + token
      }
    });
  }
}
